import { render, staticRenderFns } from "./yikuTable.vue?vue&type=template&id=7ccf5040&scoped=true&"
import script from "./yikuTable.vue?vue&type=script&lang=js&"
export * from "./yikuTable.vue?vue&type=script&lang=js&"
import style1 from "./yikuTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ccf5040",
  null
  
)

export default component.exports