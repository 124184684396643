<template>
  <div class="manage">
    <el-dialog width="800px" :visible.sync="isShow" title="添加新的移库信息">
      <yiku-form
        :form="yikuForm"
        ref="purForm"
        @submitForm="submitForm"
        @resetForm="resetForm"
      ></yiku-form>
    </el-dialog>
    <!-- 移库出库单据照片显示 -->
    <el-dialog
      width="1000px"
      :visible.sync="yikuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in yikuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yikuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 接收移库单据照片显示 -->
    <el-dialog
      width="1000px"
      :visible.sync="receiveyikuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in receiveyikuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="receiveyikuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="40%"
      :visible.sync="receiveYikuDlg_isShow"
      title="上传接收单据"
      :close-on-click-modal="false"
    >
      <receive-form
        @submitReceiveForm="submitReceiveForm"
        @resetForm="resetReceiveForm"
      ></receive-form>
    </el-dialog>
    <div class="manage-header">
      <div class="left">
        <el-button
          type="primary"
          v-if="usertype.code === '6' || usertype.code === '4'"
          @click="isShow = true"
        >
          添加新的移库信息
        </el-button>
        <el-button type="primary"> 导出数据 </el-button>
      </div>
      <div class="right">
        <el-input
          placeholder="仓库 | 材料 | 材料规格 | 接收时间"
          v-model="keyword"
          @keyup.enter.native="getTableData()"
        >
          <el-button slot="append" @click="getTableData()"> 查询 </el-button>
        </el-input>
      </div>
    </div>

    <yiku-table
      :dataList="tableData"
      :config="config"
      style="height: 100%"
      :userType="usertype"
      @Wuzi_shenhe="Wuzi_shenhe"
      @Wuzi_shenhe_jieshou="Wuzi_shenhe_jieshou"
      @Caiwu_shenhe="Caiwu_shenhe"
      @changePage="getTableData"
      @doReceive="doReceive"
      @showReceivePhotos="showReceivePhotos"
      @showPhotos="showPhotos"
    ></yiku-table>
  </div>
</template>

<script>
import YikuForm from './yikuForm'
import YikuTable from './yikuTable'
import ReceiveForm from './yikureceiveForm'
import utils from '@/assets/js/utils'
export default {
  components: {
    YikuForm,
    YikuTable,
    ReceiveForm
  },
  data() {
    return {
      isShow: false,
      keyword: '',
      usertype: '',
      user_realname: '',
      tableData: [],
      yikuForm: {},
      receiveForm: {},
      receiveYikuDlg_isShow: false,
      receiveyikuPhotos_isShow: false,
      receiveyikuphotosurls: [],
      yikuPhotos_isShow: false,
      yikuphotosurls: [],

      company: utils.getCompanyCookie(),
      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },

  methods: {
    getTableData() {
      this.config.loading = true
      const api = '/getCailiaoyikus/'
      console.log('current page:', this.config.page)
      this.$axios
        .get(api, {
          params: {
            cangku_code: this.company, //当前用户所属分公司编号code
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            console.log('totalcount:', this.config.total)
            this.tableData = res.data.data.map(item => {
              item.cailiao_cailiaoguige =
                item.cailiao_name + item.cailiaoguige_name
              if (item.receive_person != '') {
                item.receive =
                  item.receive_person + '\n' + item.receive_person_time
              }
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
            console.log('tableData:', this.tableData)
          } else {
            console.log('没有移库信息数据')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
      this.config.loading = false
    },

    //提交表单
    submitForm(formData) {
      this.yikuForm = formData
      console.log('yikuForm........:', this.yikuForm)
      this.yikuForm.added_person = this.user_realname

      this.$axios.post('/addCailiaoyiku/', this.yikuForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加移库信息成功!',
            duration: 2000
          })
          this.resetForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetForm() {
      this.isShow = false
      this.yikuForm = {}
    },
    Caiwu_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/yikuCaiwushenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },
    Wuzi_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/yikuWuzishenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },

    Wuzi_shenhe_jieshou(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/yikuWuzishenheJieshou/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },

    submitReceiveForm(formData) {
      this.receiveForm.received_photos = formData.photos
      this.receiveForm.received_date = formData.date
      this.$confirm('确定要接收吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.put('/yikuJieshou/', this.receiveForm).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '接收成功！'
            })
            this.resetReceiveForm()
            this.getTableData()
          } else {
            this.$message({
              message: '接收失败'
            })
          }
        })
      })
    },
    doReceive(row) {
      this.receiveForm.id = row.id
      this.receiveForm.receive_person = this.user_realname
      this.receiveYikuDlg_isShow = true
    },

    resetReceiveForm() {
      this.receiveYikuDlg_isShow = false
      this.receiveyikuphotosurls = []
      this.receiveForm = {}
    },

    //在table中显示接收单据照片
    showReceivePhotos(row) {
      this.receiveyikuphotosurls = []
      let photos = row.received_photos
      console.log('photos........', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器
        // let url = strs[i] //本地

        this.receiveyikuphotosurls.push(url)
      }
      console.log(this.receiveyikuphotosurls)
      this.receiveyikuPhotos_isShow = true
    },
    //在table中显示出库单据照片
    showPhotos(row) {
      this.yikuphotosurls = []
      let photos = row.photos
      console.log('yikuchuku_photos........', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器
        // let url = strs[i] //本地

        this.yikuphotosurls.push(url)
      }
      console.log(this.yikuphotosurls)
      this.yikuPhotos_isShow = true
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name
    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData()
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.el-main {
  padding-top: 0;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
