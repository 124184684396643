<template>
  <div class="common-table">
    <el-table
      v-loading="config.loading"
      :data="dataList"
      height="90%"
      highlight-current-row
      border
      stripe
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column label="序号" prop="id" width="80"></el-table-column>
      <el-table-column label="材料名称" prop="cailiao_cailiaoguige" width="160" sortable> </el-table-column>
      <el-table-column label="移库数量" prop="yikushu" width="100" sortable> </el-table-column>
      <el-table-column label="出库库房" prop="yikukufang_name" width="100" sortable> </el-table-column>
      <el-table-column label="接收库房" prop="jieshoukufang_name" width="100" sortable> </el-table-column>
      <el-table-column label="移库时间" prop="date" width="100" sortable> </el-table-column>
      <el-table-column label="移库说明" prop="remark"> </el-table-column>
      <el-table-column align="center" label="出库单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handlePhotos(scope.row)">出库单据</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="接收单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="canShowyikuPhotos(scope.row)" @click="handlereceivePhotos(scope.row)">接收单据</el-button>
        </template>
      </el-table-column>
      <el-table-column label="接收确认" width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_received ? 'success' : 'warning'">{{ scope.row.is_received ? '已接收' : '未接收' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="移库审核" width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.wuzi_shenhe ? 'success' : 'warning'">{{ scope.row.wuzi_shenhe ? '已审核' : '未审核' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="接收审核" width="80">
        <template slot-scope="scope">
          <el-tag :type="scope.row.wuzi_shenhe_jieshou ? 'success' : 'warning'">{{ scope.row.wuzi_shenhe_jieshou ? '已审核' : '未审核' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="接收人" prop="receive" width="150"></el-table-column>
      <el-table-column label="添加" prop="added" width="150"></el-table-column>
      <el-table-column label="管理" width="200">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleReceive(scope.row)" v-if="canReceive(scope.row)">接收</el-button>
          <el-button type="warning" size="mini" @click="deleteTableRow(scope.$index, scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          <el-button type="warning" size="mini" @click="handleWuzi_shenhe(scope.row)" v-if="!scope.row.wuzi_shenhe && userType.code === '4'"
            >移库物资审核</el-button
          >
          <el-button type="warning" size="mini" @click="handleWuzi_shenhe_jieshou(scope.row)" v-if="canWuzishenheJieshou(scope.row)">接收物资审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  name: 'purTable',
  props: {
    //接收的参数和参数类型
    dataList: Array,
    config: Object,
    userType: Object
  },
  data() {
    return {
      pagesize: 20,
      TableHeaderRowStyle() {
        return 'height:35px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:35px'
      },
      TableCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
      }
    }
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.kufangchuku_del) {
          if (this.userType.code === '1') return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    },
    canReceive() {
      return function(row) {
        console.log('usertype_code:', this.userType.code)
        if (row.is_received) return false
        if (row.wuzi_shenhe === true && (this.userType.code === '4' || this.userType.code === '6')) return true
        else return false
      }
    },

    canWuzishenheJieshou() {
      return function(row) {
        if (row.wuzi_shenhe_jieshou) return false
        if (row.is_received === true && this.userType.code === '4') return true
        else return false
      }
    },
    canShowyikuPhotos() {
      return function(row) {
        if (row.is_received === true) return true
        else return false
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      console.log('the current page:', page)
      this.$emit('changePage', page)
    },
    handleCaiwu_shenhe(row) {
      this.$emit('Caiwu_shenhe', row)
    },
    handleWuzi_shenhe(row) {
      this.$emit('Wuzi_shenhe', row)
    },

    handleWuzi_shenhe_jieshou(row) {
      this.$emit('Wuzi_shenhe_jieshou', row)
    },
    //接收按钮响应事件
    handleReceive(row) {
      this.$emit('doReceive', row)
    },
    //显示移库出库图片
    handlePhotos(row) {
      this.$emit('showPhotos', row)
    },
    //显示接收图片
    handlereceivePhotos(row) {
      this.$emit('showReceivePhotos', row)
    },
    deleteTableRow(index, row) {
      this.$confirm('此操作将删除该移库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/deleteCailiaoyiku/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.dataList.splice(index, 1)
          } else {
            this.$message({
              message: '删除失败，此信息不存在或已被删除'
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped></style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
