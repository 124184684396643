<template>
  <el-card>
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" size="small">
      <el-form-item label="接收日期" prop="date">
        <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.date" style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上传图片：" prop="photos">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="http://ln-jz.cn:8000/uploadImg/"
          multiple
          accept="image/png, image/jpeg,image/gif"
          list-type="picture"
          :on-remove="handleRemove"
          :file-list="fileList"
          :before-upload="beforeUploadPicture"
          :on-success="uploadSuccess"
          :auto-upload="false"
        >
          <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        </el-upload>
      </el-form-item>

      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认接收 </el-button>
      </div>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      form: {},
      file_count: 0,
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        date: [{ required: true, message: '请选择接收日期', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.form.photos = temp_str
      console.log('photos:', this.form.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    },

    //提交表单
    submitForm() {
      if (this.file_count < 1) {
        console.log('fileList_len:', this.fileList.length)
        this.$message({
          type: 'alert',
          message: '请至少上传1张图片!',
          duration: 2000
        })
      } else {
        this.$emit('submitReceiveForm', this.form)
        this.ClearForm()
      }
    },

    //清空表单
    ClearForm() {
      this.form = {}
      this.fileList = []
      this.file_count = 0
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ClearForm()
      this.$emit('resetForm')
    }
  },

  created() {}
}
</script>

<style lang="scss" scoped></style>
