<template>
  <el-form size="small" :rules="rules" ref="form" :model="form" label-width="120px">
    <el-row>
      <el-col :span="24">
        <el-form-item label="移库时间" prop="date">
          <el-date-picker value-format="yyyy-MM-dd" v-model="form.date" type="date" placeholder="选择采购日期" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item> </el-col
    ></el-row>
    <el-row v-for="(diaobodan, index) in form.diaobodans" :key="diaobodan.key">
      <el-col :span="24">
        <el-row>
          <el-col :span="10">
            <el-form-item
              label="调拨单"
              :prop="'diaobodans.' + index + '.diaobodan_id'"
              :rules="{ required: true, message: '请选择移库调拨单', trigger: 'blur' }"
            >
              <el-select
                v-model="diaobodan.diaobodan_id"
                placeholder="请选择移库调拨单"
                filterable
                remote
                :remote-method="remoteMethod"
                :loading="loading"
                @change="selDiaobodan($event, index)"
              >
                <el-option v-for="item in diaobodans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="出库数量"
              :prop="'diaobodans.' + index + '.out_amount'"
              :rules="
                ({ required: true, message: '请输入数量', trigger: 'blur' },
                { type: 'number', message: '数量必须为数字' },
                {
                  validator: (rule, value, callback) => {
                    validateLeftamount(rule, value, callback, diaobodan.left_amount)
                  },
                  trigger: 'blur'
                })
              "
            >
              <el-input v-model.number="diaobodan.out_amount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button size="small" @click.prevent="removeDomainDiaobodan(diaobodan)">删除</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <span style="color:blue">{{ msg_diaobodan[index] }} </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button @click="addDomainDiaobodan" type="warning" plain>选择另一个调拨单</el-button>
    </el-form-item>
    <el-form-item label="出库单据：" prop="photos">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="http://ln-jz.cn:8000/uploadImg/"
        multiple
        accept="image/png, image/jpeg,image/gif"
        list-type="picture"
        :on-remove="handleRemove"
        :file-list="fileList"
        :before-upload="beforeUploadPicture"
        :on-success="uploadSuccess"
        :auto-upload="false"
      >
        <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      </el-upload>
    </el-form-item>
    <el-form-item label="移库说明">
      <el-input v-model="form.remark" type="textarea" rows="3"></el-input>
    </el-form-item>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('form')"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  props: {
    type: Boolean
  },
  data() {
    return {
      msg_diaobodan: [],
      diaobodans_options: [],
      form: {
        diaobodans: [
          {
            diaobodan_id: '',
            out_amount: '',
            yikukufang_id: '',
            yikukufang_name: '',
            jieshoukufang_id: '',
            jieshoukufang_name: '',
            cailiao_id: '',
            cailiao_name: '',
            cailiaoguige_id: '',
            cailiaoguige_name: ''
          }
        ],
        remark: '' //设置默认值为空字符，以解决该项没有输入时后台requestdata['remark']key值为空的问题。
      },
      fileList: [],
      file_count: 0,
      chuku_cangkus_options: [],
      jieshou_cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      loading: false,
      company: utils.getCompanyCookie(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      isValidationOk: false,
      rules: {
        date: [{ required: true, message: '请选择采购时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getYikudiaobodansbyCode/'
          this.$axios
            .get(api, {
              params: {
                query: query,
                currentUserKufang: this.form.yikukufang //当前用户所属库房id
              }
            })
            .then(res => {
              if (res.data.code === '1000') {
                this.diaobodans_options = res.data.data.map(item => {
                  item.label = item.code
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }
            })
        }, 200)
      } else {
        this.diaobodans_options = []
      }
    },
    validateLeftamount(rule, value, callback, left_amount) {
      if (value > left_amount) {
        callback(new Error('出库数量超出未发数量！'))
      } else {
        callback()
      }
    },
    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.form.photos = temp_str
      console.log('photos:', this.form.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    getChukuCangkus() {
      this.cangkus_options = []
      this.$axios
        .get('/getCangkus/', {
          params: {
            cangku_code: this.company
          }
        })
        .then(res => {
          console.log('getCangkus list:', res.data)
          res.data.forEach(element => {
            let cangku = {}
            cangku.label = String(element.name)
            cangku.value = element.id
            this.chuku_cangkus_options.push(cangku)
          })
          console.log('chuku_cangkus_options', this.chuku_cangkus_options)
        })
    },
    getJieshouCangkus() {
      this.cangkus_options = []
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.jieshou_cangkus_options.push(cangku)
        })
        console.log('jieshou_cangkus_options', this.jieshou_cangkus_options)
      })
    },

    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.file_count < 1) {
            console.log('fileList_len:', this.fileList.length)
            this.$message({
              type: 'alert',
              message: '请至少上传1张图片!',
              duration: 2000
            })
          } else {
            console.log('validation ok!')
            this.$emit('submitForm', this.form)
            this.ClearForm()
          }
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    //清空表单
    ClearForm() {
      this.form = {
        diaobodans: [
          {
            diaobodan_id: '',
            out_amount: ''
          }
        ]
      }
      this.fileList = []
      this.file_count = 0
      this.diaobodans_options = []
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ClearForm()
      this.$emit('resetForm')
    },
    //增加调拨单表单域
    addDomainDiaobodan() {
      this.form.diaobodans.push({
        diaobodan_id: '',
        out_amount: '',
        key: Date.now()
      })
    },

    //删除调拨单表单域
    removeDomainDiaobodan(diaobodan) {
      var index = this.form.diaobodans.indexOf(diaobodan)
      if (index !== -1) {
        this.form.diaobodans.splice(index, 1)
      }
    },
    //根据选择的出库库房取得该出库库房对应的所有移库调拨单
    getDiaobodans() {
      const api = '/getYikudiaobodans/'
      this.$axios
        .get(api, {
          params: {
            currentUserKufang: this.form.yikukufang //当前用户所属库房id
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.diaobodans_options = res.data.data.map(item => {
              item.label = item.code
              item.value = item.id
              return item
            })
          } else {
            console.log('没有该库房对应的调拨单信息！')
            this.diaobodans_options = []
          }

          console.log('diaobodans_options', this.diaobodans_options)
        })
    },
    //调拨单选择处理
    selDiaobodan(e, index) {
      let diaobodan_id = e
      let form_index = index
      const api = '/getYikuDiaobodanInfo/'
      this.$axios
        .get(api, {
          params: {
            id: diaobodan_id
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            console.log('get diaobodan info:', res.data.cangku_name)
            this.form.diaobodans[form_index].yikukufang_id = res.data.yikukufang_id
            this.form.diaobodans[form_index].jieshoukufang_id = res.data.jieshoukufang_id
            this.form.diaobodans[form_index].cailiao_id = res.data.cailiao_id
            this.form.diaobodans[form_index].cailiaoguige_id = res.data.cailiaoguige_id
            this.form.diaobodans[form_index].left_amount = res.data.left_amount
            this.$set(
              this.msg_diaobodan,
              form_index,
              '[出库库房: ' +
                res.data.yikukufang_name +
                '] -- [接收库房: ' +
                res.data.jieshoukufang_name +
                '] -- [材料: ' +
                res.data.cailiao_name +
                '-' +
                res.data.cailiaoguige_name +
                '] -- [未出库数量: ' +
                res.data.left_amount +
                ']'
            )

            console.log('msg_diaobodan:', this.msg_diaobodan)
          } else {
            console.log('没有该调拨单信息！')
          }
        })
      console.log('index of diaobodan:', form_index)
      console.log('diaobodan select:', diaobodan_id)
      console.log('form:', this.form)
    }
  },
  created() {
    this.getChukuCangkus()
    this.getJieshouCangkus()
    this.getCailiaos()
    this.getCailiaoguiges()
  }
}
</script>

<style scoped></style>
